import { CloseOutlined } from '@mui/icons-material';
import axios from 'axios';

const API_URL = process.env.REACT_APP_BACKEND_URL;

const getRentals = (token) => {
  return axios.get(`${API_URL}/rentals`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getRental = (token, id) => {
  return axios.get(`${API_URL}/rentals/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const createRental = (token, rental) => {
  return axios.post(`${API_URL}/rentals`, rental, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const updateRental = (token, id, rental) => {
  return axios.put(`${API_URL}/rentals/${id}`, rental, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const updateRentalGeneral = (token, id, rental) => {
  return axios.put(`${API_URL}/rentals/${id}/general`, rental, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const updateRentalCalendar = (token, id, rental) => {
  console.log('Saving calendar config from service:', rental);
  return axios.put(`${API_URL}/rentals/${id}/calendar`, rental, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const updateRentalHomeassistant = (token, id, rental) => {
  return axios.put(`${API_URL}/rentals/${id}/homeassistant`, rental, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const deleteRental = (token, id) => {
  return axios.delete(`${API_URL}/rentals/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getSupportedEntities = (token, id, haConfigID) => {
  return axios.get(`${API_URL}/rentals/${id}/${haConfigID}/supported-entities`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getDevices = (token, id, haConfigID) => {
  return axios.get(`${API_URL}/rentals/${id}/${haConfigID}/devices`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const addDevice = (token, id, haConfigID, device) => {
  return axios.post(`${API_URL}/rentals/${id}/${haConfigID}/devices`, device, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const deleteDevice = (token, id, haConfigID, deviceID) => {
  return axios.delete(`${API_URL}/rentals/${id}/${haConfigID}/devices/${deviceID}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const testHomeAssistantConnection = (host, apiKey, haConfigID) => {
  return axios.post(`${API_URL}/rentals/test-home-assistant`, {
    host: host,
    apiKey: apiKey,
    id: haConfigID,
  });
};

const testIcalLink = (icalLink) => {
  return axios.post(`${API_URL}/rentals/test-ical-link`, {
    icalLink: icalLink,
  });
};

export default {
  getRentals,
  getRental,
  createRental,
  updateRental,
  updateRentalGeneral,
  updateRentalCalendar,
  updateRentalHomeassistant,
  deleteRental,
  testHomeAssistantConnection,
  testIcalLink,
  getSupportedEntities,
  getDevices,
  addDevice,
  deleteDevice,
};
