import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Paper, Typography, Button, Box, Dialog, Menu, MenuItem, IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Sidebar from './Sidebar';
import rentalService from '../services/rentalService';
import { useAuth0 } from '@auth0/auth0-react';
import RentalForm from './RentalForm';

const RentalList = () => {
  const [rentals, setRentals] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRentalId, setSelectedRentalId] = useState(null);

  const { logout, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const toggleCollapse = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };

  const handleAddRental = () => {
    setDialogOpen(true); // Open the dialog
  };

  const handleCloseDialog = () => {
    setDialogOpen(false); // Close the dialog
  };

  const handleFormSubmit = async (newRental) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await rentalService.addRental(newRental, token); // Add the rental using the service
      setRentals((prev) => [...prev, response.data]); // Update rentals locally
      setDialogOpen(false); // Close the dialog after submission
    } catch (error) {
      console.error('Error adding rental:', error);
    }
  };

  const handleMenuOpen = (event, rentalId) => {
    setAnchorEl(event.currentTarget);
    setSelectedRentalId(rentalId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRentalId(null);
  };

  const handleDeleteRental = async () => {
    try {
      const token = await getAccessTokenSilently();
      await rentalService.deleteRental(token, selectedRentalId); // Call the delete API
      setRentals((prev) => prev.filter((rental) => rental.id !== selectedRentalId)); // Update rentals locally
      handleMenuClose(); // Close the menu after deletion
    } catch (error) {
      console.error('Error deleting rental:', error);
    }
  };

  useEffect(() => {
    const fetchRentals = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await rentalService.getRentals(token);
        setRentals(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchRentals();
  }, [getAccessTokenSilently]);

  const handleRentalClick = (id) => {
    navigate(`/rentals/${id}`); // Navigate to rental details page
  };

  return (
    <Box sx={{ display: 'flex', height: '100vh', backgroundColor: '#f5f5f5' }}>
      <Sidebar
        open={sidebarOpen}
        collapsed={sidebarCollapsed}
        setCollapsed={setSidebarCollapsed}
        toggleSidebar={toggleSidebar}
        toggleCollapse={toggleCollapse}
        onLogout={logout}
        title="Rentals"
      />
      <Box
        sx={{
          flex: 1,
          padding: '16px',
          marginTop: '64px',
          transition: 'margin-left 0.3s',
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddRental}
          sx={{ marginBottom: 3 }}
        >
          Add Rental
        </Button>
        <Grid container spacing={4}>
          {rentals.map((rental) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              key={rental.id}
            >
              <Paper
                elevation={3}
                sx={{
                  padding: 2,
                  position: 'relative',
                  textAlign: 'center',
                  cursor: 'pointer',
                  transition: 'transform 0.2s',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                  height: '90%',
                  marginBottom: '16px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <img
                  src="https://via.placeholder.com/150"
                  alt="Rental"
                  style={{
                    width: '100%',
                    height: '180px',
                    objectFit: 'cover',
                    marginBottom: '16px',
                    borderRadius: '8px',
                  }}
                  onClick={() => handleRentalClick(rental.id)} // Navigate to details on click
                />
                <Typography variant="h6">{rental.rental_name}</Typography>
                <IconButton
                  sx={{
                    position: 'absolute',
                    bottom: 8,
                    right: 8, // Adjust position for bottom-right alignment
                  }}
                  onClick={(event) => handleMenuOpen(event, rental.id)}
                >
                  <MoreVertIcon />
                </IconButton>
              </Paper>
            </Grid>
          ))}
        </Grid>
        <Dialog open={dialogOpen} onClose={handleCloseDialog} fullWidth maxWidth="md">
          <RentalForm
            open={dialogOpen}
            handleClose={handleCloseDialog}
          />
        </Dialog>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleDeleteRental}>Delete</MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

export default RentalList;
