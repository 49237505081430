import React, { useState, useEffect } from 'react';
import apikeyService from '../services/apikeyService';
import { useAuth0 } from '@auth0/auth0-react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import {
  Button, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Box, MenuItem, Select, InputLabel, FormControl, Paper, Toolbar, Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Sidebar from './Sidebar';
import moment from 'moment';

const APIKeyList = () => {
  const [apikeys, setAPIKeys] = useState([]);
  const [rentals, setRentals] = useState([]);
  const [editingAPIKey, setEditingAPIKey] = useState(null);
  const [open, setOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const { logout, isAuthenticated, getAccessTokenSilently } = useAuth0();

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const toggleCollapse = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };

  useEffect(() => {
    fetchAPIKeys();
  }, []);

  const fetchAPIKeys = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await apikeyService.getAPIKeys(token);
      setAPIKeys(response.data);
    } catch (error) {
      console.error(error);
    }
  };


  const handleCreate = async (apikey) => {
    try {
      const token = await getAccessTokenSilently();
      await apikeyService.createAPIKey(token, apikey);
      fetchAPIKeys();
      setOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdate = async (apikey) => {
    try {
      const token = await getAccessTokenSilently();
      await apikeyService.updateAPIKey(token, editingAPIKey.id, apikey);
      setEditingAPIKey(null);
      fetchAPIKeys();
      setOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const token = await getAccessTokenSilently();
      await apikeyService.deleteAPIKey(token, id);
      fetchAPIKeys();
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteSelected = async () => {
    try {
      const token = await getAccessTokenSilently();
      await Promise.all(selectedRows.map(id => apikeyService.deleteAPIKey(token, id)));
      fetchAPIKeys();
      setSelectedRows([]); // Clear selection after deletion
    } catch (error) {
      console.error(error);
    }
  };

  const handleOpen = () => {
    setEditingAPIKey(null);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const apikey = {
      name: form.name.value,
    };
    if (editingAPIKey) {
      handleUpdate(apikey);
    } else {
      handleCreate(apikey);
    }
  };

  const columns = [
    { field: 'name', headerName: 'API Key Name', width: 125 },
    { field: 'key', headerName: 'Key', width: 525, },
    { field: 'created_date', headerName: 'Created', width: 300, },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => { setEditingAPIKey(params.row); setOpen(true); }}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDelete(params.row.id)}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  const rows = apikeys.map((apikey) => ({
    id: apikey.id,
    name: apikey.name,
    key: apikey.key,
    created_date: moment(apikey.created_date),
  }));

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <Sidebar
        open={sidebarOpen}
        collapsed={sidebarCollapsed}
        setCollapsed={setSidebarCollapsed}
        toggleSidebar={toggleSidebar}
        toggleCollapse={toggleCollapse}
        onLogout={logout}
        title="Rentals"
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '16px',
        }}
      >
        <Toolbar />

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '16px',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleOpen(null)}
            style={{ marginRight: '8px', marginTop: '16px' }}
          >
            Add APIKey
          </Button>
        </Box>

        <Paper style={{ flex: 1, overflow: 'auto' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 20]}
            checkboxSelection
            onSelectionModelChange={(newSelection) => setSelectedRows(newSelection)}
            selectionModel={selectedRows}
            components={{ Toolbar: GridToolbar }}
            style={{
              width: '100%', // Ensures it spans the container width
              height: '100%', // Fills the available height for scrolling
            }}
          />
        </Paper>
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
          <DialogTitle>{editingAPIKey ? 'Edit APIKey' : 'Add APIKey'}</DialogTitle>
          <DialogContent>
            <Box component="form" id="apikey-form" onSubmit={handleFormSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2, minWidth: '500px' }}>
              <TextField
                name="name"
                label="Name"
                fullWidth
                margin="normal"
                defaultValue={editingAPIKey ? editingAPIKey.name : ''}
                required
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Cancel
            </Button>
            <Button form="apikey-form" type="submit" color="primary">
              {editingAPIKey ? 'Update' : 'Add'}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};

export default APIKeyList;
