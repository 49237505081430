import React, { useEffect, useState } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  AppBar,
  Toolbar,
  Typography,
  Box,
  Divider,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { SpaceDashboard, EventNote, Home, Key, ExitToApp, ChevronLeft, ChevronRight } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate, useLocation } from 'react-router-dom';

const drawerWidth = 240;
const collapsedWidth = 60;

const Sidebar = ({ open, toggleSidebar, collapsed, setCollapsed, onLogout }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isInitialized, setIsInitialized] = useState(false);
  const [title, setTitle] = useState('');

  const routeTitles = {
    '/dashboard': 'Dashboard',
    '/bookings': 'Bookings',
    '/rentals': 'Rentals',
    '/apikeys': 'API Keys',
  };

  useEffect(() => {
    const currentTitle = routeTitles[location.pathname] || '';
    setTitle(currentTitle);
  }, [location.pathname]);

  useEffect(() => {
    const persistedCollapsed = localStorage.getItem('sidebarCollapsed');
    if (persistedCollapsed !== null) {
      setCollapsed(JSON.parse(persistedCollapsed));
    }
    setIsInitialized(true);
  }, [setCollapsed]);

  const handleToggleCollapse = () => {
    const newCollapsedState = !collapsed;
    setCollapsed(newCollapsedState);
    localStorage.setItem('sidebarCollapsed', JSON.stringify(newCollapsedState));
  };

  const handleNavigation = (path) => {
    navigate(path);
    if (isMobile) {
      toggleSidebar();
    }
  };

  if (!isInitialized) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex' }}>
      {/* AppBar */}
      <AppBar
        position="fixed"
        color="default"
        sx={{
          zIndex: theme.zIndex.drawer + 1,
          transition: 'margin 0.3s',
          marginLeft: collapsed && !isMobile ? `${collapsedWidth}px` : `${drawerWidth}px`,
        }}
      >
        <Toolbar>
          {isMobile ? (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleSidebar}
              sx={{ marginRight: 2 }}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="toggle collapse"
              onClick={handleToggleCollapse}
              sx={{ marginRight: 2 }}
            >
              {collapsed ? <ChevronRight /> : <ChevronLeft />}
            </IconButton>
          )}
          <Typography variant="h6" noWrap>
            {title}
          </Typography>
        </Toolbar>
      </AppBar>

      {/* Sidebar */}
      <Drawer
        variant={isMobile ? 'temporary' : 'permanent'}
        open={open}
        onClose={toggleSidebar}
        sx={{
          width: collapsed && !isMobile ? collapsedWidth : drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: collapsed && !isMobile ? collapsedWidth : drawerWidth,
            boxSizing: 'border-box',
            transition: 'width 0.3s',
          },
        }}
      >
        <Toolbar />
        <Divider />
        <List>
          {[
            { path: '/dashboard', label: 'Dashboard', icon: <SpaceDashboard /> },
            { path: '/bookings', label: 'Bookings', icon: <EventNote /> },
            { path: '/rentals', label: 'Rentals', icon: <Home /> },
            { path: '/apikeys', label: 'API Keys', icon: <Key /> },
          ].map(({ path, label, icon }) => (
            <ListItem button key={path} onClick={() => handleNavigation(path)}>
              <ListItemIcon>{icon}</ListItemIcon>
              {(!collapsed || isMobile) && <ListItemText primary={label} />} {/* Ensure titles display on mobile */}
            </ListItem>
          ))}
        </List>
        <Divider />
        <List
          sx={{
            marginTop: isMobile ? 0 : 'auto',
            paddingBottom: isMobile ? '16px' : 0,
            position: isMobile ? 'absolute' : 'static',
            bottom: isMobile ? 0 : 'unset',
            width: '100%',
          }}
        >
          <ListItem button onClick={onLogout}>
            <ListItemIcon>
              <ExitToApp />
            </ListItemIcon>
            {(!collapsed || isMobile) && <ListItemText primary="Logout" />}
          </ListItem>
        </List>
      </Drawer>
    </Box>
  );
};

export default Sidebar;
