import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography, Snackbar } from '@mui/material';
import rentalService from '../services/rentalService';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate } from 'react-router-dom';

const RentalGeneralSection = ({ rentalData }) => {
  const { logout, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [formState, setFormState] = useState({
    rental_name: '',
    address: '',
    timezone: '',
  });
  const [isSaving, setIsSaving] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success', // 'success', 'info', 'error'
  });

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  useEffect(() => {
    if (rentalData) {
      setFormState({
        rental_name: rentalData.rental_name || '',
        address: rentalData.address || '',
        timezone: rentalData.timezone || '',
      });
    }
  }, [rentalData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = async (payload) => {
    setIsSaving(true); // Start saving
    try {
      const token = await getAccessTokenSilently();
      console.log('Saving general info:', payload);
      await rentalService.updateRentalGeneral(token, rentalData.id, payload);
      showSnackbar('General information saved successfully!', 'success');
    } catch (error) {
      console.error(error);
      showSnackbar('Failed to save general information.', 'error');
    } finally {
      setIsSaving(false); // End saving
    }
  };

  const showSnackbar = (message, severity = 'success') => {
    setSnackbar({ open: true, message, severity });
  };

  const handleSnackbarClose = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  return (
    <Box>
      <Typography variant="h6">General Information</Typography>
      <TextField
        name="rental_name"
        label="Rental Name"
        fullWidth
        margin="normal"
        value={formState.rental_name}
        onChange={handleChange}
        disabled={isSaving} // Disable inputs while saving
      />
      <TextField
        name="address"
        label="Address"
        fullWidth
        margin="normal"
        value={formState.address}
        onChange={handleChange}
        disabled={isSaving} // Disable inputs while saving
      />
      <TextField
        name="timezone"
        label="Timezone"
        fullWidth
        margin="normal"
        value={formState.timezone}
        onChange={handleChange}
        disabled={isSaving} // Disable inputs while saving
      />
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleSave(formState)}
        disabled={isSaving} // Disable button while saving
      >
        {isSaving ? 'Saving...' : 'Save Changes'}
      </Button>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbar.message}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        ContentProps={{
          'aria-describedby': 'message-id',
          style: {
            backgroundColor:
              snackbar.severity === 'success'
                ? 'green'
                : snackbar.severity === 'info'
                ? 'blue'
                : 'red',
          },
        }}
      />
    </Box>
  );
};

export default RentalGeneralSection;
