import React, { useRef, useEffect } from 'react';
import { TextField } from '@mui/material';

const AddressAutocomplete = ({ onAddressSelect, defaultValue }) => {
  const inputRef = useRef(null);

  useEffect(() => {
    const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;

    const loadGoogleMapsScript = (callback) => {
      if (!document.querySelector(`script[src="https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places"]`)) {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
        script.async = true;
        script.defer = true;
        script.onload = callback;
        document.head.appendChild(script);
      } else {
        callback();
      }
    };

    const initAutocomplete = () => {
      if (window.google && window.google.maps) {
        const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current, {
          types: ['geocode'], // Restrict to geographical locations
        });
        autocomplete.addListener('place_changed', () => {
          const place = autocomplete.getPlace();
          if (place && place.geometry) {
            const { lat, lng } = place.geometry.location;
            const latitude = lat();
            const longitude = lng();
            onAddressSelect({ place, latitude, longitude });
          }
        });
      }
    };

    loadGoogleMapsScript(initAutocomplete);
  }, [onAddressSelect]);

  return (
    <TextField
      inputRef={inputRef}
      name="address"
      label="Address"
      fullWidth
      margin="normal"
      value={defaultValue}
      placeholder="Enter address"
    />
  );
};

export default AddressAutocomplete;
