import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography, Checkbox, FormControlLabel, Snackbar } from '@mui/material';
import rentalService from '../services/rentalService';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate } from 'react-router-dom';

const RentalCalendarConfigSection = ({ rentalData }) => {
  const { logout, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [calendarConfig, setCalendarConfig] = useState({
    vrbo_ical_link: '',
    airbnb_ical_link: '',
    custom_ical_link: '',
    integration_enabled: false,
  });
  const [isSaving, setIsSaving] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success', // 'success', 'info', 'error'
  });

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  useEffect(() => {
    if (rentalData?.calendar_config) {
      setCalendarConfig({
        vrbo_ical_link: rentalData.calendar_config.vrbo_ical_link || '',
        airbnb_ical_link: rentalData.calendar_config.airbnb_ical_link || '',
        custom_ical_link: rentalData.calendar_config.custom_ical_link || '',
        integration_enabled: rentalData.calendar_config.integration_enabled || false,
      });
    }
  }, [rentalData]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setCalendarConfig((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSave = async (payload) => {
    setIsSaving(true); // Start saving
    try {
      const token = await getAccessTokenSilently();
      const response = await rentalService.updateRentalCalendar(token, rentalData.id, payload);
      showSnackbar(response.message || 'Calendar configuration saved successfully!', 'success');
    } catch (error) {
      console.error(error);
      showSnackbar(error.response?.data?.message || 'Failed to save calendar configuration.', 'error');
    } finally {
      setIsSaving(false); // End saving
    }
  };

  const showSnackbar = (message, severity = 'success') => {
    setSnackbar({ open: true, message, severity });
  };

  const handleTestLink = async (linkName) => {
    try {
      const token = await getAccessTokenSilently();
      const linkValue = calendarConfig[linkName];
      if (!linkValue) throw new Error('Link is empty');

      const response = await rentalService.testIcalLink(linkValue);
      showSnackbar(response.message || `${linkName.replace('_', ' ')} is valid!`, 'success');
    } catch (error) {
      console.error(error);
      showSnackbar(error.response?.data?.message || `Failed to validate ${linkName.replace('_', ' ')}.`, 'error');
    }
  };

  const handleSnackbarClose = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  return (
    <Box>
      <Typography variant="h6">Calendar Configuration</Typography>
      <FormControlLabel
        control={
          <Checkbox
            name="integration_enabled"
            checked={calendarConfig.integration_enabled}
            onChange={handleChange}
            disabled={isSaving} // Disable input while saving
          />
        }
        label="Enable Calendar Integration"
      />
      <Box display="flex" alignItems="center" gap={2} mb={2}>
        <TextField
          name="airbnb_ical_link"
          label="Airbnb iCal Link"
          fullWidth
          margin="normal"
          value={calendarConfig.airbnb_ical_link}
          onChange={handleChange}
          disabled={isSaving}
        />
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => handleTestLink('airbnb_ical_link')}
          disabled={isSaving}
        >
          Test
        </Button>
      </Box>
      <Box display="flex" alignItems="center" gap={2} mb={2}>
        <TextField
          name="custom_ical_link"
          label="Custom iCal Link"
          fullWidth
          margin="normal"
          value={calendarConfig.custom_ical_link}
          onChange={handleChange}
          disabled={isSaving}
        />
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => handleTestLink('custom_ical_link')}
          disabled={isSaving}
        >
          Test
        </Button>
      </Box>
      <Box display="flex" alignItems="center" gap={2} mb={2}>
        <TextField
          name="vrbo_ical_link"
          label="VRBO iCal Link"
          fullWidth
          margin="normal"
          value={calendarConfig.vrbo_ical_link}
          onChange={handleChange}
          disabled={isSaving}
        />
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => handleTestLink('vrbo_ical_link')}
          disabled={isSaving}
        >
          Test
        </Button>
      </Box>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleSave(calendarConfig)}
        disabled={isSaving} // Disable button while saving
      >
        {isSaving ? 'Saving...' : 'Save Changes'}
      </Button>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbar.message}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        ContentProps={{
          'aria-describedby': 'message-id',
          style: {
            backgroundColor:
              snackbar.severity === 'success'
                ? 'green'
                : snackbar.severity === 'info'
                ? 'blue'
                : 'red',
          },
        }}
      />
    </Box>
  );
};

export default RentalCalendarConfigSection;
