import React from 'react';
import { Box, Typography } from '@mui/material';

const DeviceItem = ({ device }) => (
  <Box
    style={{
      lineHeight: 1.6, // Ensure consistent line spacing
    }}
  >
    <Typography variant="subtitle1">{device.friendly_name || 'Unknown Device'}</Typography>
    <Typography variant="body2" color="textSecondary">
      EntityID: {device.entity_id}
    </Typography>
    <Typography variant="body2" color="textSecondary">
      Manufacturer: {device.manufacturer}, Model: {device.model}
    </Typography>
  </Box>
);

export default DeviceItem;
