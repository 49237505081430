import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Typography, Tabs, Tab, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import rentalService from '../services/rentalService';
import Sidebar from './Sidebar';
import RentalGeneralSection from './RentalGeneralSection';
import RentalCalendarConfigSection from './RentalCalendarConfigSection';
import RentalHomeAssistantConfigSection from './RentalHomeAssistantConfigSection';
import { useAuth0 } from '@auth0/auth0-react';

const RentalDetails = () => {
  const { id } = useParams(); // Get rental ID from URL
  const [activeTab, setActiveTab] = useState(0);
  const [rentalData, setRentalData] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const { logout, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate(); // Use useNavigate hook for programmatic navigation

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  useEffect(() => {
    fetchRentalData();
  }, []);

  const fetchRentalData = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await rentalService.getRental(token, id);
      setRentalData(response.data);
    } catch (error) {
      console.error('Failed to fetch rental data:', error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const toggleCollapse = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };

  const handleClose = () => {
    navigate('/rentals'); // Navigate back to the RentalList page
  };

  const renderTabContent = () => {
    if (!rentalData) {
      return <Typography>Loading rental details...</Typography>;
    }

    switch (activeTab) {
      case 0:
        return <RentalGeneralSection rentalData={rentalData} />;
      case 1:
        return <RentalCalendarConfigSection rentalData={rentalData} />;
      case 2:
        return <RentalHomeAssistantConfigSection rentalData={rentalData} />;
      default:
        return null;
    }
  };

  return (
    <Box sx={{ display: 'flex', height: '100vh', position: 'relative' }}>
      {/* Global Sidebar */}
      <Sidebar
        open={sidebarOpen}
        collapsed={sidebarCollapsed}
        setCollapsed={setSidebarCollapsed}
        toggleSidebar={toggleSidebar}
        toggleCollapse={toggleCollapse}
        onLogout={logout}
        title="Rentals"
      />

      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#f5f5f5',
        }}
      >
        {/* Main Content Beneath AppBar */}
        <Box
          sx={{
            position: 'relative',
            marginTop: '64px', // Push content below the app bar
            height: 'calc(100vh - 64px)', // Adjust height to exclude the app bar
            overflow: 'auto',
            padding: '16px', // Add padding for consistent spacing
          }}
        >
          {/* Close Button Positioned Below the AppBar */}
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: '16px', // Position 16px below the container's top
              right: '16px', // Align to the right
              zIndex: 10, // Ensure visibility over other elements
            }}
          >
            <Close />
          </IconButton>

          {/* Tabs */}
          <Box sx={{ marginTop: '48px' }}> {/* Add space above tabs */}
            <Tabs value={activeTab} onChange={handleTabChange} centered>
              <Tab label="General" />
              <Tab label="Calendar" />
              <Tab label="Home Assistant" />
            </Tabs>
          </Box>

          {/* Tab Content */}
          <Box sx={{ padding: '16px' }}>{renderTabContent()}</Box>
        </Box>
      </Box>
    </Box>

  );
};

export default RentalDetails;
