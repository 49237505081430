import { useState } from "react";
import { IconButton, Snackbar } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const CopyToClipboardButton = ({ url }) => {
  const [open, setOpen] = useState(false);

  const handleClick = (e) => {
    e.stopPropagation(); // Prevents interference with the link click
    setOpen(true);
    try {
      navigator.clipboard.writeText(url);
    } catch (err) {
      console.error("Failed to copy text: ", err);
      setOpen(false); // Close the snackbar if copying fails
    }
  };

  return (
    <>
      <IconButton onClick={handleClick} >
        <ContentCopyIcon />
      </IconButton>
      <Snackbar
        message="Copied to clipboard"
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={2000}
        onClose={() => setOpen(false)}
        open={open}
      />
    </>
  );
};

export default CopyToClipboardButton;