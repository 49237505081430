import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  Typography,
  TextField,
  Grid,
} from '@mui/material';
import moment from 'moment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const initialFormState = {
  rental_id: '',
  rental_name: '',
  check_in_date: null,
  check_in_time: '16:00',
  check_out_date: null,
  check_out_time: '11:00',
  guest_name: '',
  guest_email: '',
  guest_phone_number: '',
  guest_pincode: '',
};

const BookingForm = ({ open, handleClose, handleFormSubmit, editingBooking, editingBookingRental, rentals }) => {
  const [formState, setFormState] = useState(initialFormState);

  useEffect(() => {
    if (!open) {
      setFormState(initialFormState);
    }
    if (editingBooking) {
      setFormState({
        rental_id: editingBooking.rental_id || '',
        rental_name: editingBooking.rental_name || '',
        check_in_date: moment(editingBooking.check_in).format('YYYY-MM-DD') || '',
        check_in_time: moment(editingBooking.check_in).format('HH:mm') || '',
        check_out_date: moment(editingBooking.check_out).format('YYYY-MM-DD') || '',
        check_out_time: moment(editingBooking.check_out).format('HH:mm') || '',
        guest_name: editingBooking.guest_name || '',
        guest_email: editingBooking.guest_email || '',
        guest_phone_number: editingBooking.phone_number || '',
        guest_pincode: editingBooking.pincode || '',
      });
    }
  }, [editingBooking, open]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>{editingBooking ? 'Edit Booking' : 'Add Booking'}</DialogTitle>
      <DialogContent>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box
            component="form"
            id="booking-form"
            onSubmit={handleFormSubmit}
            sx={{ mt: 2 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Booking Details</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="rental_id"
                  label="Rental"
                  select
                  fullWidth
                  value={formState.rental_id}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                  SelectProps={{
                    native: true,
                  }}
                  required
                >
                  <option value="">Select Rental</option>
                  {rentals.map((rental) => (
                    <option key={rental.id} value={rental.id}>
                      {rental.rental_name}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="check_in_date"
                  label="Check-in Date"
                  type="date"
                  fullWidth
                  value={formState.check_in_date}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="check_in_time"
                  label="Check-in Time"
                  type="time"
                  fullWidth
                  value={formState.check_in_time}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="check_out_date"
                  label="Check-out Date"
                  type="date"
                  fullWidth
                  value={formState.check_out_date}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="check_out_time"
                  label="Check-out Time"
                  type="time"
                  fullWidth
                  value={formState.check_out_time}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="guest_name"
                  label="Guest Name"
                  fullWidth
                  value={formState.guest_name}
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="guest_email"
                  label="Guest Email"
                  type="email"
                  fullWidth
                  value={formState.guest_email}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="guest_phone_number"
                  label="Phone Number"
                  type="tel"
                  fullWidth
                  value={formState.guest_phone_number}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="guest_pincode"
                  label="Pin Code"
                  fullWidth
                  value={formState.guest_pincode}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Box>
        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button form="booking-form" type="submit" color="primary">
          {editingBooking ? 'Update' : 'Add'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BookingForm;
