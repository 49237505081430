import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  Typography,
  Divider,
  FormControlLabel,
  Checkbox,
  TextField,
  Grid,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AddressAutocomplete from './AddressAutocomplete';
import rentalService from '../services/rentalService';
import '../index.css';
import bookingService from '../services/bookingService';
import { useAuth0 } from '@auth0/auth0-react';

const initialFormState = {
  rental_name: '',
  address: '',
  timezone: '',
  default_check_in_time: '16:00',
  default_check_out_time: '11:00',
  calendar_config: {
    calendar_vrbo_ical_link: '',
    calendar_airbnb_ical_link: '',
    calendar_custom_ical_link: '',
    calendar_integration_enabled: false,
  },
  home_assistant_config: {
    home_assistant_integration_enabled: false,
    home_assistant_host: '',
    home_assistant_api_key: '',
    home_assistant_guest_label: 'staykey-guest-access',
  },
};

const initialStatusCheckState = {
  vrboStatus: '',
  airbnbStatus: '',
  customIcalStatus: '',
  HomeAssistantStatus: '',
};

const RentalForm = ({ open, handleClose, editingRental }) => {
    // Single form state to manage all fields
  const [formState, setFormState] = useState(initialFormState);
  const [statusCheckState, setStatusCheckState] = useState(initialStatusCheckState);
  const { logout, getAccessTokenSilently } = useAuth0();

  // Effect to update formState when editingRental changes
  useEffect(() => {
    if (!open) {
      setFormState(initialFormState); // Reset to initial state when form is closed
      setStatusCheckState(initialStatusCheckState)
    }
    if (editingRental) {
      setFormState({
        rental_name: editingRental.rental_name || '',
        address: editingRental.address || '',
        timezone: editingRental.timezone || '',
        default_check_in_time: editingRental.default_check_in_time || '',
        default_check_out_time: editingRental.default_check_out_time || '',
        calendar_config: {
          calendar_vrbo_ical_link: editingRental.calendar_config?.vrbo_ical_link || '',
          calendar_airbnb_ical_link: editingRental.calendar_config?.airbnb_ical_link || '',
          calendar_custom_ical_link: editingRental.calendar_config?.custom_ical_link || '',
          calendar_integration_enabled: editingRental.calendar_config?.integration_enabled || false,
        },
        home_assistant_config: {
          home_assistant_integration_enabled: editingRental.home_assistant_config?.integration_enabled || false,
          home_assistant_guest_label: editingRental.home_assistant_config?.guest_label || '',
          home_assistant_api_key: editingRental.home_assistant_config?.api_key || '',
          home_assistant_host: editingRental.home_assistant_config?.host || '',
        },
      });
      setStatusCheckState(initialStatusCheckState)
    }
  }, [editingRental]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const fieldKey = name.startsWith('home_assistant_')
      ? 'home_assistant_config'
      : name.startsWith('calendar_')
      ? 'calendar_config'
      : null;

    if (fieldKey) {
      setFormState((prevState) => ({
        ...prevState,
        [fieldKey]: {
          ...prevState[fieldKey],
          [name]: type === 'checkbox' ? checked : value,
        },
      }));
    } else {
      setFormState((prevState) => ({
        ...prevState,
        [name]: type === 'checkbox' ? checked : value,
      }));
    }
  };

  const handleCalendarChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    setFormState((prevState) => ({
      ...prevState,
      calendar_config: {
        ...prevState.calendar_config,
        [name]: type === 'checkbox' ? checked : value,
      },
    }));
  };

  const handleHomeAssistantChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    setFormState((prevState) => ({
      ...prevState,
      home_assistant_config: {
        ...prevState.home_assistant_config,
        [name]: type === 'checkbox' ? checked : value,
      },
    }));
  };

  const handleFormSubmit = (event) => {
    event.preventDefault(); // Make sure this is called on an actual event object
    const form = event.target;
  
    const rental = {
      rental_name: form.rental_name.value,
      address: form.address.value,
      timezone: form.timezone.value, // New field for Timezone
      default_check_in_time: form.default_check_in_time.value,
      default_check_out_time: form.default_check_out_time.value, // Use the checkOutTime state
      calendar_config: {
        integration_enabled: form.calendar_integration_enabled.checked,
        vrbo_ical_link: form.calendar_vrbo_ical_link.value,
        airbnb_ical_link: form.calendar_airbnb_ical_link.value,
        custom_ical_link: form.calendar_custom_ical_link.value,
      },
      home_assistant_config: {
        integration_enabled: form.home_assistant_integration_enabled.checked,
        host: form.home_assistant_host.value,
        api_key: form.home_assistant_api_key.value,
        guest_label: form.home_assistant_guest_label.value,
      },
    };
  
    handleCreate(rental);
  };

  const handleCreate = async (rental) => {
    try {
      const token = await getAccessTokenSilently();
      await rentalService.createRental(token, rental);
      handleClose(); // Close the dialog on success
    } catch (error) {
      console.error(error);
    }
  };
  

  const handleAddressSelect = async ({ place, latitude, longitude }) => {
    const formattedAddress = place.formatted_address;

    setFormState((prevState) => ({
      ...prevState,
      address: formattedAddress,
    }));

    // Fetch timezone based on coordinates
    const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
    const timestamp = Math.floor(Date.now() / 1000);
    const timeZoneApiUrl = `https://maps.googleapis.com/maps/api/timezone/json?location=${latitude},${longitude}&timestamp=${timestamp}&key=${apiKey}`;

    try {
      const response = await fetch(timeZoneApiUrl);
      const data = await response.json();
      if (data.status === 'OK') {
        setFormState((prevState) => ({
          ...prevState,
          timezone: data.timeZoneId,
        }));
      } else {
        console.error('Failed to fetch timezone:', data.status);
      }
    } catch (error) {
      console.error('Error fetching timezone:', error);
    }
  };

  const handleIcalStatusMessage = async (icalSource, statusMessage) => {

    if (icalSource == 'vrbo') {
      setStatusCheckState({vrboStatus: statusMessage})
      return;
    }
    if (icalSource == 'airbnb') {
      setStatusCheckState({airbnbStatus: statusMessage})
      return;
    }
    if (icalSource == 'customIcal') {
      setStatusCheckState({customIcalStatus: statusMessage})
      return;
    }
  };

  const handleICalLinkTest = async (icalLink, icalSource) => {

    if (!icalLink) {
      handleIcalStatusMessage(icalSource, 'Please provide both host and API key.')
      return;
    }

    try {
      await rentalService.testIcalLink(icalLink);
      handleIcalStatusMessage(icalSource, 'iCal link is valid!');
    } catch (error) {
      handleIcalStatusMessage(icalSource, 'Invalid iCal link.');
    }
  };

  const handleHomeAssistantTest = async () => {
    if (!formState.home_assistant_config.home_assistant_host) {
      setStatusCheckState({
        HomeAssistantStatus: 'Please provide both host and API key.',
      });
      return;
    }
  
    try {
      const response = await rentalService.testHomeAssistantConnection(formState.home_assistant_config.home_assistant_host, formState.home_assistant_config.home_assistant_api_key);
      setStatusCheckState({
        HomeAssistantStatus: 'Connection successful!',
      });
    } catch (error) {
      setStatusCheckState({
        HomeAssistantStatus: 'Failed to connect. Please check the host and API key.',
      });
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        component="form"
        onSubmit={handleFormSubmit}
        sx={{ padding: 3, display: 'flex', flexDirection: 'column', gap: 3 }}
      >
        <Typography variant="h5" sx={{ textAlign: 'center', marginBottom: 2 }}>
          Rental Details
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              name="rental_name"
              label="Rental Name"
              fullWidth
              value={formState.rental_name}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AddressAutocomplete
            onAddressSelect={handleAddressSelect}
            value={formState.address} 
            onChange={(e) => setFormState((prev) => ({ ...prev, address: e.target.value }))} // Handle manual typing
          />
          </Grid>
          <Grid item xs={12} sm={6}>
          <TextField
              name="timezone"
              label="Timezone"
              fullWidth
              margin="normal"
              value={formState.timezone}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="default_check_in_time"
              label="Default Check-In Time"
              type="time"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={formState.default_check_in_time}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="default_check_out_time"
              label="Default Check-Out Time"
              type="time"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={formState.default_check_out_time}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Divider />
        <Typography variant="h6">Calendar Configuration</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  name="calendar_integration_enabled"
                  checked={formState.calendar_config.calendar_integration_enabled}
                  onChange={handleCalendarChange}
                />
              }
              label="Enable Calendar Integration"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="calendar_vrbo_ical_link"
              label="VRBO iCal Link"
              fullWidth
              margin="normal"
              value={formState.calendar_config.calendar_vrbo_ical_link}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="calendar_airbnb_ical_link"
              label="Airbnb iCal Link"
              fullWidth
              margin="normal"
              value={formState.calendar_config.calendar_airbnb_ical_link}
              onChange={handleCalendarChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="calendar_custom_ical_link"
              label="Custom iCal Link"
              fullWidth
              margin="normal"
              value={formState.calendar_config.calendar_custom_ical_link}
              onChange={handleCalendarChange}
            />
          </Grid>
        </Grid>
        <Divider />
        <Typography variant="h6">Home Assistant Configuration</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  name="home_assistant_integration_enabled"
                  checked={
                    formState.home_assistant_config.home_assistant_integration_enabled
                  }
                  onChange={handleHomeAssistantChange}
                />
              }
              label="Enable Home Assistant Integration"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="home_assistant_host"
              label="Home Assistant Host"
              fullWidth
              margin="normal"
              value={formState.home_assistant_config.home_assistant_host}
              onChange={handleHomeAssistantChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="home_assistant_api_key"
              label="Home Assistant API Key"
              fullWidth
              margin="normal"
              value={formState.home_assistant_config.home_assistant_api_key}
              onChange={handleHomeAssistantChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="home_assistant_guest_label"
              label="Guest Label"
              fullWidth
              margin="normal"
              value={formState.home_assistant_config.home_assistant_guest_label}
              onChange={handleHomeAssistantChange}
            />
          </Grid>
        </Grid>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Save
          </Button>
        </DialogActions>
      </Box>
    </LocalizationProvider>
  );
};

export default RentalForm;
