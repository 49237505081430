import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  Snackbar,
  IconButton,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
} from '@mui/material';
import { Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import DeleteIcon from '@mui/icons-material/Delete';
import rentalService from '../services/rentalService';
import DeviceItem from './DeviceItem';

const RentalHomeAssistantConfigSection = ({ rentalData }) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [homeAssistantConfig, setHomeAssistantConfig] = useState({
    integration_enabled: false,
    host: '',
    api_key: '',
    guest_label: '',
  });
  const [devices, setDevices] = useState([]);
  const [supportedDevices, setSupportedDevices] = useState([]);
  const [addDevicesDialogOpen, setAddDevicesDialogOpen] = useState(false);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isTesting, setIsTesting] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  useEffect(() => {
    if (rentalData?.home_assistant_config) {
      setHomeAssistantConfig({
        integration_enabled: rentalData.home_assistant_config.integration_enabled || false,
        host: rentalData.home_assistant_config.host || '',
        api_key: rentalData.home_assistant_config.api_key || '',
        guest_label: rentalData.home_assistant_config.guest_label || '',
      });
      fetchDevices();
    }
  }, [rentalData]);

  const fetchDevices = async () => {
    setIsLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const response = await rentalService.getDevices(
        token,
        rentalData.id,
        rentalData.home_assistant_config.id
      );
      setDevices(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error('Error fetching devices:', error);
      setDevices([]);
      showSnackbar('Failed to fetch devices', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = async (payload) => {
    setIsSaving(true);
    try {
      const token = await getAccessTokenSilently();
      await rentalService.updateRentalHomeassistant(token, rentalData.id, payload);
      showSnackbar('Configuration saved successfully', 'success');
    } catch (error) {
      console.error('Error saving configuration:', error);
      showSnackbar('Failed to save configuration', 'error');
    } finally {
      setIsSaving(false);
    }
  };

  const handleDeleteDevice = async (deviceID) => {
    setIsLoading(true);
    try {
      const token = await getAccessTokenSilently();
      await rentalService.deleteDevice(
        token,
        rentalData.id,
        rentalData.home_assistant_config.id,
        deviceID
      );
      setDevices((prevDevices) => prevDevices.filter((device) => device.id !== deviceID));
      showSnackbar('Device deleted successfully', 'success');
    } catch (error) {
      console.error('Error deleting device:', error);
      showSnackbar('Failed to delete device', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSupportedDevices = async () => {
    setIsLoading(true); // Start loading
    try {
      const token = await getAccessTokenSilently();
      const response = await rentalService.getSupportedEntities(
        token,
        rentalData.id,
        rentalData.home_assistant_config.id
      );
      setSupportedDevices(Array.isArray(response.data) ? response.data : []);
      showSnackbar('Supported devices loaded successfully', 'info');
    } catch (error) {
      console.error('Error fetching supported devices:', error);
      setSupportedDevices([]); // Fallback to empty array
      showSnackbar('Failed to load supported devices', 'error');
    } finally {
      setIsLoading(false); // End loading
    }
  };
  

  const handleAddDevices = async () => {
    setIsLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const requests = selectedDevices.map((device) =>
        rentalService.addDevice(token, rentalData.id, rentalData.home_assistant_config.id, {
          parent_id: rentalData.home_assistant_config.id,
          entity_id: device.entity_id,
          device_id: device.device_id,
          friendly_name: device.friendly_name,
          manufacturer: device.manufacturer,
          model: device.model,
          integration: device.integration,
          supported_device_id: device.supported_device_id,
        })
      );
      await Promise.all(requests);
      setAddDevicesDialogOpen(false);
      setSelectedDevices([]);
      fetchDevices();
      showSnackbar('Devices added successfully', 'success');
    } catch (error) {
      console.error('Error adding devices:', error);
      showSnackbar('Failed to add devices', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleTestConnection = async () => {
    setIsTesting(true);
    try {
      const { host, api_key } = homeAssistantConfig;
      if (!host) {
        throw new Error('Host and API key are required');
      }
      const response = await rentalService.testHomeAssistantConnection(host, api_key, rentalData.home_assistant_config.id);
      showSnackbar('Success testing connection', 'success');
    } catch (error) {
      console.error('Error testing connection:', error);
      showSnackbar(
        error.response?.data?.message || 'Failed to test connection',
        'error'
      );
    } finally {
      setIsTesting(false);
    }
  };

  const showSnackbar = (message, severity = 'success') => {
    setSnackbar({ open: true, message, severity });
  };

  const filteredSupportedDevices = supportedDevices.filter(
    (supportedDevice) =>
      !devices.some((device) => device.entity_id === supportedDevice.entity_id)
  );

  const handleSnackbarClose = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  return (
    <Box>
      <Typography variant="h6">Home Assistant Configuration</Typography>
      <FormControlLabel
        control={
          <Checkbox
            name="integration_enabled"
            checked={homeAssistantConfig.integration_enabled}
            onChange={(e) =>
              setHomeAssistantConfig((prev) => ({
                ...prev,
                integration_enabled: e.target.checked,
              }))
            }
          />
        }
        label="Enable Home Assistant Integration"
      />
      <TextField
        name="host"
        label="Home Assistant Host"
        fullWidth
        margin="normal"
        value={homeAssistantConfig.host}
        onChange={(e) =>
          setHomeAssistantConfig((prev) => ({ ...prev, host: e.target.value }))
        }
      />
      <TextField
        name="api_key"
        label="Home Assistant API Key"
        fullWidth
        margin="normal"
        type="password"
        value={homeAssistantConfig.api_key}
        onChange={(e) =>
          setHomeAssistantConfig((prev) => ({ ...prev, api_key: e.target.value }))
        }
      />
      <TextField
        name="guest_label"
        label="Guest Label"
        fullWidth
        margin="normal"
        value={homeAssistantConfig.guest_label}
        onChange={(e) =>
          setHomeAssistantConfig((prev) => ({ ...prev, guest_label: e.target.value }))
        }
      />
      <Box display="flex" alignItems="center" gap={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSave(homeAssistantConfig)}
          disabled={isSaving}
        >
          {isSaving ? <CircularProgress size={20} /> : 'Save Changes'}
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleTestConnection}
          disabled={isTesting}
        >
          {isTesting ? <CircularProgress size={20} /> : 'Test Connection'}
        </Button>
      </Box>

      <Box mt={4}>
        <Typography variant="h6">Devices</Typography>
        {isLoading ? (
          <Box display="flex" justifyContent="center" mt={2}>
            <CircularProgress />
          </Box>
        ) : (
          <List>
            {devices.map((device) => (
              <ListItem key={device.id} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <DeviceItem device={device} />
                <IconButton onClick={() => handleDeleteDevice(device.id)}>
                  <DeleteIcon />
                </IconButton>
              </ListItem>
            ))}
          </List>
        )}
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            setAddDevicesDialogOpen(true);
            fetchSupportedDevices(); // Fetch devices when opening the dialog
          }}
        >
          Add Devices
        </Button>

      </Box>

      <Dialog
        open={addDevicesDialogOpen}
        onClose={() => setAddDevicesDialogOpen(false)}
      >

      <DialogTitle>Select Devices to Add</DialogTitle>
      <DialogContent>
        <FormGroup>
          {filteredSupportedDevices.map((device) => (
            <FormControlLabel
              key={device.entity_id}
              control={
                <Checkbox
                  checked={selectedDevices.some((d) => d.entity_id === device.entity_id)}
                  onChange={() =>
                    setSelectedDevices((prev) =>
                      prev.some((d) => d.entity_id === device.entity_id)
                        ? prev.filter((d) => d.entity_id !== device.entity_id)
                        : [...prev, device]
                    )
                  }
                />
              }
              label={<DeviceItem device={device} />}
            />
          ))}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setAddDevicesDialogOpen(false)}>Cancel</Button>
        <Button variant="contained" color="primary" onClick={handleAddDevices}>
          Add Selected
        </Button>
      </DialogActions>
    </Dialog>

    <Snackbar
      open={snackbar.open}
      autoHideDuration={6000}
      onClose={handleSnackbarClose}
      message={snackbar.message}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      ContentProps={{
        'aria-describedby': 'message-id',
        style: {
          backgroundColor:
            snackbar.severity === 'success'
              ? 'green'
              : snackbar.severity === 'info'
              ? 'blue'
              : 'red',
        },
      }}
    />
  </Box>
  );
};

export default RentalHomeAssistantConfigSection;

                        
